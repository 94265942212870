import React, { useState } from "react";
import "./App.css";
import Benefiti from "./benefiti.jpeg";
import axios from 'axios'

const App = () => {
  const [benefits, setBenefits] = useState([
    {
      id: 1,
      name: "Vaučer za pregled lekara specijaliste",
      cost: 30,
      isChosen: false,
      isDisabled: false,
    },
    {
      id: 2,
      name: "Vaučer za pregled oftalmologa",
      cost: 30,
      isChosen: false,
      isDisabled: false,
    },
    {
      id: 3,
      name: "Online konsultacija sa lekarom specijalistom (dve sesije)",
      cost: 30,
      isChosen: false,
      isDisabled: false,
    },
    {
      id: 4,
      name: "Online sesija sa psihoterapeutom",
      cost: 30,
      isChosen: false,
      isDisabled: false,
    },
    {
      id: 5,
      name: "Vaučer za uslugu masaže (dve masaže)",
      cost: 30,
      isChosen: false,
      isDisabled: false,
    },
    {
      id: 6,
      name: "Online grupni časovi joge (2x nedeljno)",
      cost: 30,
      isChosen: false,
      isDisabled: false,
    },
    {
      id: 7,
      name: "Premium kartica za pristup teretanama (30 termina)",
      cost: 30,
      isChosen: false,
      isDisabled: false,
    },
    {
      id: 8,
      name: "Grupni časovi poslovnog engleskog jezika online (2x nedeljno)",
      cost: 30,
      isChosen: false,
      isDisabled: false,
    },
    {
      id: 9,
      name: "Sesija individualnog koučinga",
      cost: 30,
      isChosen: false,
      isDisabled: false,
    },
    {
      id: 10,
      name: "Kurs Excel-a online (2x nedeljno)",
      cost: 30,
      isChosen: false,
      isDisabled: false,
    },
    {
      id: 11,
      name: "Sesija ličnog finansijskog savetovanja za pojedince",
      cost: 30,
      isChosen: false,
      isDisabled: false,
    },
    {
      id: 12,
      name: "Vaučeri za korišćenje usluga automobilskog prevoza",
      cost: 30,
      isChosen: false,
      isDisabled: false,
    },
    {
      id: 13,
      name: "Usluge iznajmljivanja bicikla (dve nedelje)",
      cost: 30,
      isChosen: false,
      isDisabled: false,
    },
    {
      id: 14,
      name: "Vaučeri za kupovinu i dostavu gotove hrane iz izabranih restorana.",
      cost: 30,
      isChosen: false,
      isDisabled: false,
    },
    {
      id: 15,
      name: "Vaučeri za kupovinu u knjižarama",
      cost: 30,
      isChosen: false,
      isDisabled: false,
    },
    {
      id: 16,
      name: "Celodnevni pristup coworking prostoru (4 dana)",
      cost: 30,
      isChosen: false,
      isDisabled: false,
    },
    {
      id: 17,
      name: "Individualna sesija savetovanja za roditelje na izabranu temu",
      cost: 30,
      isChosen: false,
      isDisabled: false,
    },
    {
      id: 18,
      name: "Grupni online časovi za predškolce i osnovce 2x nedeljno",
      cost: 30,
      isChosen: false,
      isDisabled: false,
    },
    {
      id: 19,
      name: "Vaučeri za kupovinu igračaka za decu",
      cost: 30,
      isChosen: false,
      isDisabled: false,
    },
    {
      id: 20,
      name: "Sređivanje kuće 2x mesečno po 4 sata",
      cost: 30,
      isChosen: false,
      isDisabled: false,
    },
  ]);
  const tokens = () => {
    let tokensAvailable = 120;

    benefits.forEach((benefit) => {
      if (benefit.isChosen === true) {
        tokensAvailable -= benefit.cost;
      }
    });

    return tokensAvailable;
  };

  const handleChange = (index) => {
    setBenefits(
      benefits.map((benefit, i) =>
        index === i ? { ...benefit, isChosen: !benefit.isChosen } : benefit
      )
    );
  };

  const submit = () => {
    const data = benefits.filter((benefit) =>
      benefit.isChosen === true
    ).map(benefit => `${benefit.id}`)
    const payload = JSON.stringify({"ids": data});

    const config = {
      method: 'post',
      url: 'https://demo.benefit.bstorm.app/api/v1/dashboard/post',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : payload
    };

    axios(config)
    .then(res => alert(res.data.message))
    .catch(err => console.log(err))
  }
  return (
    <div className="App">
      <div className="container">
        <img
          src={Benefiti}
          alt="Benefiti"
          style={{ width: "100%", borderRadius: 4 }}
        />
        <hr style={{ marginTop: 24 }} />
        <p className="text" style={{ margin: "16px 0" }}>
          Tokens: {tokens()}
        </p>
        <hr />
        <div className="titleAndLegend">
          <h3 className="listTitle">Benefits:</h3>
          <div className="legend">
            <div className="leftLegendItem">
              <div
                className="outerRing"
                style={{
                  border: `2px solid #1568bf`,
                }}
              >
                <div
                  className="innterRing"
                  style={{
                    background: "#1568bf",
                  }}
                ></div>
              </div>
              <p className="text" style={{ margin: 0 }}>
                Chosen Benefit
              </p>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                className="outerRing"
                style={{
                  border: `2px solid red`,
                }}
              >
                <div
                  className="innterRing"
                  style={{
                    background: "red",
                  }}
                ></div>
              </div>
              <p className="text" style={{ margin: 0 }}>
                Not chosen Benefit
              </p>
            </div>
          </div>
        </div>

        <div>
          {benefits.map((benefit, i) => (
            <button
              key={i}
              className="button"
              disabled={tokens() < benefit.cost && !benefit.isChosen}
              onClick={() => handleChange(i)}
            >
              <div className="statusAndName">
                <div
                  className="outerRing"
                  style={{
                    border: `2px solid ${benefit.isChosen ? "#1568bf" : "red"}`,
                  }}
                >
                  <div
                    className="innterRing"
                    style={{
                      background: `${benefit.isChosen ? "#1568bf" : "red"}`,
                    }}
                  ></div>
                </div>
                <p style={{width: 300, textAlign: 'left'}}>{benefit.name}</p>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p>Cost: </p>
                <p style={{ width: 25, textAlign: "right" }}>{benefit.cost}</p>
              </div>
            </button>
          ))}
        </div>
        <button onClick={() => submit()} type="button" className="submit">
          Submit your combination
        </button>
      </div>
    </div>
  );
};

export default App;
